
import { mapGetters } from 'vuex'
import Button from '~/components/buttons/Button'
import DevLogs from '~/components/error/dev-logs'
import CarouselErrorPage from '~/components/error/carousel-error-page'
export default {
  components: { Button, DevLogs, CarouselErrorPage },
  props: {
    error: {
      type: Object,
      default: () => ({
        message: '',
        statusCode: 404,
      }),
    },
  },
  computed: {
    ...mapGetters({
      story: 'common/getErrorStory',
    }),
  },
  mounted() {
    this.$sentryException(this.error, {
      source: 'error-404.vue',
      level: 'fatal',
      extra: `[error-404]: ${this.error}`,
    })
  },
}
