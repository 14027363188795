export const state = () => ({
  profile: null,
})

export const getters = {
  getProfile(state) {
    return state.profile
  },
  getAllReviews(state) {
    return state.profile?.results?.reviews || null
  },
  getPublishedReviews(state) {
    return (
      state.profile?.results?.reviews?.filter(({ published }) => published) ||
      null
    )
  },
  getUnpublishedReviews(state) {
    return (
      state.profile?.results?.reviews?.filter(({ published }) => !published) ||
      null
    )
  },
  getReviewedProductIds(state) {
    return (
      state.profile?.results?.reviews?.map(({ product_external_id }) =>
        product_external_id.toString()
      ) || null
    )
  },
  // getReviewedProductsGroupedByOrderId(state) {
  //   return state.profile
  //     ? state.profile.results.reduce((acc, result) => {
  //         if (!acc[result.orderId]) {
  //           acc[result.orderId] = []
  //         }
  //         acc[result.orderId].push(result.review.productId)
  //         return acc
  //       }, {})
  //     : null
  // },
}

export const mutations = {
  setProfile(state, profile) {
    state.profile = profile
  },
  setProfileReviews(state, reviews) {
    state.profile.results = reviews
  },
}

export const actions = {
  async init({ commit, getters }, customer) {
    try {
      let profile = getters.getProfile

      // GET THE PROFILE
      if (!profile?.email && customer?.email) {
        await this.$axios
          .post('/api/judgeme/getReviewer', {
            email: customer.email,
          })
          .catch((error) => {
            throw new Error('REVIEWS STORE - getReviewer: ' + error)
          })
          .then((result) => {
            if (result?.data?.reviewer) {
              commit('setProfile', result.data.reviewer)
            } else {
              throw new Error('REVIEWS STORE - Customer not found ' + result)
            }
          })
      }

      // GET THE REVIEWS
      profile = getters.getProfile
      if (profile?.id) {
        await this.$axios
          .post('/api/judgeme/reviews/index', {
            reviewer_id: profile.id,
          })
          .catch((error) => {
            throw new Error('REVIEWS STORE - getReviews: ' + error)
          })
          .then((result) => {
            if (result?.data) {
              commit('setProfileReviews', result.data)
            } else {
              throw new Error('REVIEWS STORE - Reviews not found ' + result)
            }
          })
      }
    } catch (error) {
      this.app.$sentryException(error, {
        source: 'reviews store',
        level: 'warning',
        extra: 'REVIEWS STORE - init(): ' + error,
      })
    }
  },
  clear({ commit }) {
    try {
      commit('setProfile', null)
    } catch (e) {
      this.app.$sentryException(e, {
        source: 'reviews store',
        level: 'warning',
        extra: 'REVIEWS STORE - clear()',
      })
      throw new Error(e)
    }
  },
}
