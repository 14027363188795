
import * as Sentry from '@sentry/vue'
import { mapState, mapMutations, mapActions, mapGetters } from 'vuex'
import LoleContentNotifications from '~/components/menu/lole-content-notifications'
import HeaderDesktop from '~/components/header/header-desktop'
import HeaderMobile from '~/components/header/header-mobile'
import Backdrop from '~/components/common/backdrop'
import FooterDesktop from '~/components/footer/footer-desktop'
import FooterMobile from '~/components/footer/footer-mobile'
import CurrencyDetectChangeModal from '~/components/common/currency-detect-modal'
export default {
  components: {
    LoleContentNotifications,
    HeaderDesktop,
    HeaderMobile,
    Backdrop,
    FooterDesktop,
    FooterMobile,
    CurrencyDetectChangeModal,
  },
  data() {
    return {
      validCurrencies: {
        CAD: 'CAD',
        USD: 'USD',
        EUR: 'EUR',
      },
      currencyDetectChange: false,
    }
  },
  head() {
    return {
      ...this.$nuxtI18nSeo(),
      script: [
        {
          src: `//static.klaviyo.com/onsite/js/klaviyo.js?company_id=${this.$config.KLAVIYO.PUB_API_KEY}`,
          type: 'text/javascript',
        },
      ],
    }
  },
  computed: {
    ...mapState({
      notification: (state) => state.notification,
      background: (state) => state.common.background,
      vxLocale: (state) => state.i18n.locale,
    }),
    ...mapGetters({
      vxCustomer: 'customer/getCustomer',
      vxCustomerAccessToken: 'customer/getCustomerAccessToken',
      vxIsOpenMobile: 'menu/getIsOpenMobile',
      vxFooter: 'common/getFooter',
      vxSignupNewsletter: 'common/getSignupNewsletter',
      vxIsOpenDesktop: 'menu/getIsOpenDesktop',
      vxIsSearchOpen: 'navigation/getSearchDesktopOpen',
      vxCurrency: 'currency/getCurrency',
    }),
  },
  watch: {
    $route() {
      document.body.classList.remove('no-scroll')
      this.dataLayerGTM()
      this.activeEventKlaviyo()
      this.checkCustomerSession()
    },
    vxCustomer() {
      this.activeEventKlaviyo()
      // SENTRY SET USER
      Sentry.setUser({
        email: this.vxCustomer?.email,
        id: this.vxCustomer?.id,
      })
    },
    vxCurrency() {
      this.vxInitRecentlyViewed()
    },
  },
  beforeMount() {
    if (this.$route.query?.currency) {
      const newQuery = Object.assign({}, this.$route.query)
      delete newQuery.currency
      this.$router.replace({ query: newQuery })
    }
  },
  async mounted() {
    this.initializeClient()
    this.dataLayerGTMFirstLoad()
    this.dataLayerGTM()

    await this.initializeCustomer()
    await this.vxInitRecentlyViewed()
    await this.initializeLocation()

    this.checkCustomerSession()

    if (this.vxCustomer?.id && this.vxCustomer?.email) {
      await this.initializeReviews()
      this.activeEventKlaviyo()

      Sentry.setUser({
        email: this.vxCustomer.email,
        id: this.vxCustomer.id,
      })
    } else {
      Sentry.setUser(null)
    }
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll)
    window.removeEventListener('resize', this.vxSetWindowWidth)
  },
  methods: {
    ...mapMutations({
      vxSetScroll: 'setScroll',
      vxSetWindowWidth: 'responsive/setWindowWidth',
      vxSetCurrency: 'currency/setCurrency',
    }),
    ...mapActions({
      vxCustomerInit: 'customer/initialize',
      vxCloseAllDesktop: 'navigation/closeAllDesktop',
      vxCloseAllMobile: 'navigation/closeAllMobile',
      vxReviewsInit: 'reviews/init',
      vxInitRecentlyViewed: 'recentlyViewedProducts/init',
      vxCommonInit: 'common/init',
      vxUpdateBuyerIdentity: 'cart/updateBuyerIdentity',
      vxRetrieveCart: 'cart/retrieveCart',
      vxCreateCart: 'cart/createCart',
    }),
    async initializeCustomer() {
      try {
        await this.vxCustomerInit().then(async () => {
          try {
            const cartId = this.$cookies.get('lole-cart')
            if (cartId) await this.vxRetrieveCart({ cartId })
            else await this.vxCreateCart([])
          } catch (error) {
            this.app.$sentryException(error, {
              source: 'store',
              level: 'fatal',
              extra: 'init application failed on retrieveCart or createCart',
            })
          }
        })
      } catch (error) {
        this.app.$sentryException(error, {
          source: 'store',
          level: 'fatal',
          extra: 'init application failed : dispatch(customer/initialize)',
        })
      }
    },
    async initializeReviews() {
      if (this.vxCustomer) {
        try {
          await this.vxReviewsInit(this.vxCustomer)
        } catch (error) {
          this.$sentryException(error, {
            source: 'default layout',
            level: 'warning',
            extra: 'Reviews init failed',
          })
        }
      }
    },
    async initializeLocation() {
      try {
        let GeolocData = null
        const countryFromCurrency = this.vxCurrency?.substring(0, 2) || null
        const currencyAuto = this.getCurrencyAuto() || null
        const currencyOverride = this.getCurrencyOverride() || null
        const cachedGeolocData = await this.getCachedGeoloc()
        const clientIp = await this.$location.getIp()

        if (currencyAuto || currencyOverride || !clientIp) return

        if (!cachedGeolocData || clientIp !== cachedGeolocData?.ip) {
          GeolocData = await this.$location.getGeolocData(clientIp)
          this.saveCachedGeoloc(GeolocData)
        } else {
          GeolocData = cachedGeolocData
        }

        // force change the currency to geolocData value
        if (
          countryFromCurrency !== GeolocData?.countryCode &&
          this.vxCurrency !== GeolocData?.currencyCode &&
          !currencyOverride
        ) {
          const currencyToSet = GeolocData?.isEuropeanUnion
            ? 'EUR'
            : this.validCurrencies?.[GeolocData.currencyCode] || 'USD'

          await this.updateCurrencyForSite(currencyToSet)
          this.setCurrencyAuto(currencyToSet)

          this.toggleCurrencyDetectModal()
        }
      } catch (error) {
        this.$sentryException(error, {
          source: 'default layout',
          level: 'info',
          extra: `INITIALIZE LOCATION - failed to check an init geolocation: ${error}`,
        })
      }
    },
    async updateCurrencyForSite(currency) {
      await this.vxUpdateBuyerIdentity(currency)
      await this.vxSetCurrency(currency)
      if (this.vxCart?.id) {
        await this.vxRetrieveCart({ cartId: this.vxCart.id })
      }
      this.vxCommonInit()
      this.$nuxt.refresh()
    },
    initializeClient() {
      window.addEventListener('scroll', this.handleScroll)
      window.addEventListener('resize', this.vxSetWindowWidth)
      this.vxSetWindowWidth()
    },
    handleScroll() {
      this.vxSetScroll(window.scrollY)
    },
    dataLayerGTMFirstLoad() {
      try {
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
          originalLocation:
            document.location.protocol +
            '//' +
            document.location.hostname +
            document.location.pathname +
            document.location.search,
        })
      } catch (e) {
        this.$sentryException(e, {
          source: 'GTM page product',
          level: 'warning',
          extra: 'GTM layout failed',
        })
      }
    },
    dataLayerGTM() {
      try {
        this.$gtm.push({
          uri:
            document.location.pathname +
            document.location.search +
            document.location.hash,
          language: this.$i18n.locale.includes('fr') ? 'FR' : 'EN',
          isMobile: !this.$store.getters['responsive/getIsWindowLarge'],
          currency: this.$i18n.locale.includes('ca')
            ? 'CAD'
            : this.$i18n.locale.includes('eu')
            ? 'EUR'
            : 'USD',
          loginStatus: this.vxCustomer ? 'logged' : 'disconnected',
          userId: this.vxCustomer
            ? this.vxCustomer.id.replace('gid://shopify/Customer/', '')
            : null,
          event: 'page_view',
        })
      } catch (e) {
        this.$sentryException(e, {
          source: 'GTM page product',
          level: 'warning',
          extra: 'GTM layout failed',
        })
      }
    },
    activeEventKlaviyo() {
      if (this.vxCustomer)
        this.$klaviyoEvents.ActiveOnSite(this.$route.path, this.vxCustomer)
    },
    getCurrencyOverride() {
      return this.$cookies.get('lole-currency-ovr')
    },
    getCurrencyAuto() {
      return this.$cookies.get('lole-currency-auto')
    },
    setCurrencyAuto(currency) {
      const SEVEN_DAYS = 1000 * 60 * 60 * 24 * 7
      this.$cookies.set('lole-currency-auto', currency, {
        maxAge: SEVEN_DAYS,
        path: '/',
      })
    },
    getCachedGeoloc() {
      const data = localStorage.getItem('lole-geoloc')
      if (!data) return null

      const { value, expiration } = JSON.parse(data)
      const now = new Date()
      if (now.getTime() > expiration) {
        localStorage.removeItem('lole-geoloc')
        return null
      }
      return value || null
    },
    saveCachedGeoloc(value) {
      const SEVEN_DAYS = 1000 * 60 * 60 * 24 * 7
      const now = new Date()
      const expiration = now.getTime() + SEVEN_DAYS
      localStorage.setItem('lole-geoloc', JSON.stringify({ value, expiration }))
    },
    toggleCurrencyDetectModal() {
      this.currencyDetectChange = !this.currencyDetectChange
    },
    checkCustomerSession() {
      if (this.$route.path.includes('/account')) {
        if (!this.vxCustomerAccessToken?.accessToken)
          this.$router.push(this.localePath(`/signin`))
      }
    },
  },
}
