import { parseProductFromShopify } from '~/helpers/shopify/product'
export default {
  methods: {
    async fetchCollectionFromSearchSpring(handle, currency) {
      try {
        if (!handle) throw new Error('No handle provided')
        const siteId =
          this.$config.SEARCHSPRING_SITE_IDS[this.$i18n.locale.toUpperCase()]
        const ssUrl = new URL(
          'https://api.searchspring.net/api/search/search.json'
        )
        ssUrl.searchParams.append('siteId', siteId)
        ssUrl.searchParams.append('resultsFormat', 'native')
        ssUrl.searchParams.append('format', 'json')
        ssUrl.searchParams.append('bgfilter.collection_handle', handle)
        ssUrl.searchParams.append('resultsPerPage', '10')
        ssUrl.searchParams.append('page', '1')

        const { data } = await this.$axios.get(
          decodeURIComponent(ssUrl.toString())
        )
        const parsedProducts = this.$searchspring.parseProducts.parse(
          data,
          currency
        )?.resultProducts

        return parsedProducts?.length > 0 ? parsedProducts : []
      } catch (error) {
        this.$sentryException(error, {
          source: 'fetchProducts Mixin - fetchCollectionFromSearchSpring()',
          level: 'warning',
          extra: `Error : ${error}`,
        })
      }
    },
    async fetchProductsFromShopify(products, currency) {
      // products = [
      //  {
      //   "colors":"G178",
      //   "internal_type":"LSW4417",
      //  },
      //  {
      //   "colors":"G178,N415,R397",
      //   "internal_type":"LSW4501",
      //  },
      //  {
      //   "colors":"W441, BB44",
      //   "internal_type":"LSW4427",
      //  }
      // ]
      try {
        if (!products?.length > 0) throw new Error('No products provided')

        const internalTypes = products.reduce((acc, { internal_type }) => {
          acc.push(internal_type)
          return acc
        }, [])

        const { data } = await this.$shopify.product.getProductsByInternalType(
          internalTypes,
          currency
        )

        const parsedProducts = products.reduce(
          (acc, { internal_type, colors }) => {
            const colorsContent = colors
              ?.replace(/\s/g, '')
              ?.toUpperCase()
              ?.split(',')

            colorsContent.forEach((color) => {
              const product = data.products.edges.find(
                ({ node }) =>
                  node.handle.split('-').pop().toUpperCase() === internal_type
              )
              const parsedProduct = parseProductFromShopify(
                product?.node,
                this.$i18n.locale,
                color
              )?.result
              if (parsedProduct) acc.push(parsedProduct)
            })
            return acc
          },
          []
        )

        // ORDERED LIKE STORYBLOK
        const blokProductsOrder = products?.reduce(
          (acc, { internal_type }, index) => {
            acc[internal_type] = index
            return acc
          },
          {}
        )
        return parsedProducts
          .filter(({ availability }) => availability)
          .sort(
            (a, b) =>
              blokProductsOrder[a.internal_type] -
              blokProductsOrder[b.internal_type]
          )
      } catch (error) {
        this.$sentryException(error, {
          source: 'fetchProducts Mixin - fetchProductsFromShopify()',
          level: 'warning',
          extra: `Error : ${error}`,
        })
      }
    },
  },
}
